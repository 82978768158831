<template>
  <div class="settins-page">
    <div class="settins-left">
      <div class="setting-type">
        <p>{{ method == 1 ? "线上办理" : "线下办理" }}</p>
        <span v-if="setting.method == 0" @click="selectMethod"
          ><i class="el-icon-sort"></i> 切换</span
        >
      </div>

      <div class="identitys-list">
        <div
          class="identitys-item"
          :class="identitysActive == index ? 'identitys-item-active' : ''"
          v-for="(item, index) in visaIdentitys"
          :key="index"
          @click="checkIdentity(index)"
        >
          {{ item.name }}
        </div>
        <el-tag
          type=""
          effect="plain"
          class="identitys-button"
          style="white-space: nowrap"
          @click="openDialogIdentitys"
        >
          配置身份类型
        </el-tag>
      </div>

      <div class="identitys-select" style="white-space: nowrap">
        <!-- <div  @click="openDialogIdentitys">选择身份类型</div> -->
      </div>
    </div>
    <div class="settins-right">
      <div class="box-input">
        <div class="box-input-left">
          <!-- @change="getQuery" -->
          <el-select
            clearable
            v-model="form.isRequire"
            placeholder="是否必须"
            @change="getQueryvisadocs"
          >
            <el-option
              v-for="item in requires"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <el-input
            @input="getQueryvisadocs"
            v-model="form.name"
            style="width: 340px; margin-right: 22px"
            placeholder="请输入资料名称"
          ></el-input>

          <!-- <el-select clearable v-model="productForm.visaTypeId" @change="getQuery" placeholder="请选择签证大类">
                        <el-option v-for="item in visaType" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
  

                    <el-select clearable v-model="productForm.countryId" @change="getQuery" placeholder="请选择国家">
                        <el-option v-for="item in country" :key="item.id" :label="item.zhName" :value="item.id">
                        </el-option>
                    </el-select> -->
        </div>

        <div class="box-input-btn">
          <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
          <el-button class="box-input-btn-add" @click="editData">配置资料</el-button>
          <el-button plain type="primary" icon="el-icon-upload" @click="importOpenFn">导入资料</el-button>
        </div>
      </div>

      <div
        class="box-table"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
      >
        <el-table
          :data="queryVisaList"
          :header-cell-style="{
            backgroundColor: '#F2F4F7',
            color: '#333',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '12px',
          }"
          :cell-style="{ 'text-align': 'center' }"
          :height="$store.state.product.screen ? '820px' : '470px'"
        >
          <el-table-column prop="categoryName" label="一级大类">
          </el-table-column>
          <el-table-column prop="name" label="资料名称"> </el-table-column>
          <el-table-column prop="desc" label="描述" min-width="160">
            <template slot-scope="scope">
              <div class="desc-tooltip-box">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.desc"
                  placement="top-start"
                >
                  <div class="tooltip-text">{{ scope.row.desc }}</div>
                </el-tooltip>
                <el-button
                  type="text"
                  size="small"
                  @click="openDescFn(scope.row)"
                  >修改</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="legend" label="样例">
            <template slot-scope="scope">
              <div>
                <el-image
                  v-if="scope.row.legend"
                  style="width: 88px; height: 55px; border-radius: 8px"
                  :src="scope.row.legend"
                  :preview-src-list="[scope.row.legend]"
                >
                </el-image>
                <span v-else> 未配置 </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="文书模板" min-width="120">
            <template slot-scope="scope">
              <el-upload
                v-if="!scope.row.path"
                action=""
                :http-request="(file) => uploadDocFn(file, scope.row)"
              >
                <el-button type="text" size="small">上传</el-button>
              </el-upload>
              <div class="desc-tooltip-box" v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.path"
                  placement="top-start"
                >
                  <!-- <div class="tooltip-text">预览</div> -->
                  <el-link type="primary" :href="scope.row.path" target="_blank"
                    >预览</el-link
                  >
                </el-tooltip>
                <el-button
                  type="text"
                  icon="el-icon-circle-close"
                  @click="removeDocFn(scope.row)"
                ></el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序" min-width="150">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.sort"
                @change="handleNumberChange($event, scope.row)"
                :min="0"
                label="排序"
                style="width: 150px"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="isElectron" label="是否电子">
            <template slot-scope="scope">
              <div>
                <el-switch
                  v-model="scope.row.isElectron"
                  active-color="#3888E9"
                  inactive-color="#CCCCCC"
                  @change="switchIsElectronChange(scope.row)"
                >
                </el-switch>
                <div>{{scope.row.isElectron?'在线上传' : ''}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="isMail" label="是否邮寄">
            <template slot-scope="scope">
              <div>
                <el-switch
                  v-model="scope.row.isMail"
                  active-color="#3888E9"
                  inactive-color="#CCCCCC"
                  @change="switchIsMailChange(scope.row)"
                >
                </el-switch>
                <div>{{scope.row.isMail?'快递邮寄' : ''}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="isNeed" label="是否必须">
            <template slot-scope="scope">
              <div>
                <el-switch
                  v-model="scope.row.isNeed"
                  active-color="#3888E9"
                  inactive-color="#CCCCCC"
                  @change="switchIsNeedChange(scope.row)"
                >
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="isMultiple" label="是否多张">
            <template slot-scope="scope">
              <div>
                <el-switch
                  v-model="scope.row.isMultiple"
                  active-color="#3888E9"
                  inactive-color="#CCCCCC"
                  @change="switchIsMultipleChange(scope.row)"
                >
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center">
            <template slot-scope="scope">
              <div class="edit-wrap">
                <span
                  style="color: #3888e9; cursor: pointer"
                  class="del"
                  @click="delProduct(scope.row.id)"
                  >删除</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-pagination">
        <el-pagination
          @current-change="currentChange"
          :current-page="pageIndex"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 配置资料 -->
    <el-dialog
      :visible.sync="zlDataShow"
      width="80vw"
      top="10vh"
      class="zl-class"
    >
      <div class="dialog-member-wrap" style="margin-bottom: 20px">
        <div class="dialog-member-title">
          <span class="dialog-member-name">配置资料</span>
          <div class="dialog-member-right">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              @click="addCustomDocFn()"
              >新增自定义资料</el-button
            >
            <div class="dialog-close" @click="zlDataShow = false">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        calss="dialog-zl-wrap"
        v-loading="zlDataLoading"
        element-loading-spinner="el-icon-loading"
      >
        <el-table
          :data="docList"
          :header-cell-style="{
            backgroundColor: '#F2F4F7',
            color: '#333',
            fontWeight: 'bold',
            textAlign: 'center',
          }"
          style="min-width: 100%"
          height="70vh"
          :key="tableKey"
          v-loading="zlDiaLoading"
          element-loading-spinner="el-icon-loading"
        >
          <el-table-column prop="name" label="类别" width="140px">
          </el-table-column>
          <el-table-column prop="docs" label="项目">
            <template slot-scope="scope">
              <el-checkbox-group v-model="checkBoxArr">
                <div
                  style="margin-top: 10px"
                  v-if="
                    scope.row.name == '其他资料' || scope.row.name == '迁移资料'
                  "
                >
                  <el-input
                    placeholder="请输入内容"
                    v-model="input3"
                    class="input-with-select"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="searDocs(scope.row)"
                    ></el-button>
                  </el-input>
                </div>
                <el-divider
                  v-if="
                    scope.row.name == '其他资料' || scope.row.name == '迁移资料'
                  "
                  >搜索结果</el-divider
                >
                <el-checkbox
                  v-show="
                    (searchArr.length > 0 && scope.row.name == '其他资料') ||
                    scope.row.name == '迁移资料'
                  "
                  :label="item.id"
                  v-for="(item, index) in searchArr"
                  :key="item.id + '' + index"
                  class="checkbox-item"
                  :title="item.name"
                >
                  <div>{{ item.name }}</div>
                  <el-button
                    v-if="item.orgId > 0"
                    type="text"
                    icon="el-icon-edit"
                    circle
                    @click="editCustomDocFn(item)"
                  ></el-button>
                </el-checkbox>
                <el-divider
                  v-if="
                    scope.row.name == '其他资料' || scope.row.name == '迁移资料'
                  "
                  >正常显示</el-divider
                >
                <el-checkbox
                  :label="item.id"
                  v-for="(item, index) in scope.row.docs"
                  :key="index"
                  class="checkbox-item"
                  :title="item.name"
                >
                  <div>{{ item.name }}</div>
                  <el-button
                    v-if="item.orgId > 0"
                    type="text"
                    icon="el-icon-edit"
                    circle
                    @click="editCustomDocFn(item)"
                  >
                  </el-button>
                </el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialog-zl-btn">
        <el-button class="close2 zl-btn" @click="zlDataShow = false"
          >取消</el-button
        >
        <el-button class="default zl-btn" @click="settingSubmit"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!-- 成员管理 添加or编辑 -->
    <el-dialog
      :visible.sync="dialogIdentitys"
      width="464px"
      class="identitys-class"
      :close-on-click-modal="false"
    >
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">选择身份类型</span>
          <div class="dialog-close" @click="dialogIdentitys = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div class="identitys-dialog-list">
        <div
          class="identitys-dialog-item"
          @click="selectActive(item)"
          :class="[item.active ? 'identitys-dialog-item-active' : '']"
          v-for="(item, index) in identitysList"
          :key="index"
        >
          {{ item.name }}

          <div class="identitys-dialog-item-img">
            <img
              :src="`https://file.sport-syy.com/visa/admin/images/pc/identity${index}.png`"
              v-if="item.active"
              alt=""
            />
            <img
              :src="`https://file.sport-syy.com/visa/admin/images/pc/identityNo${index}.png`"
              v-else
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="identitys-dialog-btn">
        <el-button class="default identitys-btn" @click="identitysCheck"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改描述 -->
    <el-dialog
      :visible.sync="dialogDesc"
      width="464px"
      title="修改描述"
      :close-on-click-modal="false"
    >
      <div calss="dialog-zl-wrap">
        <el-form>
          <el-form-item>
            <el-input
              v-model="rowInfo.desc"
              type="textarea"
              placeholder="请输入内容"
              size="small"
              show-word-limit
              :autosize="{ minRows: 10, maxRows: 20 }"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-zl-btn">
        <el-button class="close2 zl-btn" @click="dialogDesc = false"
          >取消</el-button
        >
        <el-button
          class="default zl-btn"
          @click="confirmDescFn"
          :loading="descLoading"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!-- 配置资料 -- 新增自定义资料 -->
    <AddCustomDocDialog
      :visible="addCustomDocShow"
      :row="customDocInfo"
      @close="addCustomDocShow = false"
      @refresh="refreshDocSettingFn"
    ></AddCustomDocDialog>
    
    <!-- 导入资料 -->
    <ImportDataDialog :visible="importDocShow" @close="importDocShow = false" @refresh="refreshDocSettingFn"></ImportDataDialog>
  </div>
</template>

<script>
import { getvisaidentitys, getdefaultvisaidentitys, visaidentitys, queryvisadocs, getvisadocs, doclist, modifyvisadoc, modifymultipleorisneed, deletevisadoc, uploaddocdesc, uploaddoc, uploaddocsort } from '@/api/personal.js'
import alyoss from "@/utils/alyoss";
import AddCustomDocDialog from './addCustomDocDialog.vue';
import ImportDataDialog from './ImportDataDialog';
export default {
    components: { AddCustomDocDialog, ImportDataDialog },
    data() {
        return {
            id: '',
            visaIdentitys: [],
            docList: [],
            visaDocs: [],
            checkBoxArr: [],
            queryVisaList: [],
            identitysList: '',
            identitysActive: -1,
            method: 0,
            num: 0,
            tableKey: 1,
            dialogIdentitys: false,
            zlDataShow: false,
            zlDataLoading: false,
            zlDiaLoading: false,
            loading: false,
            pageIndex: 1,
            pageTotal: 0,
            requires: [
                {
                    name: '是',
                    value: 1
                },
                {
                    name: '否',
                    value: 2
                },
            ],
            form: {
                isRequire: '',
                name: '',
            },
            input3: '',
            searchArr:[],
            dialogDesc: false, //是否打开描述
            descLoading: false,
            rowInfo: {},

            addCustomDocShow: false,
            customDocInfo: {},
            importLoading: false,
            importDocShow: false,
            importInfo: { }
        }
    },
    mounted() {
        this.id = this.setting.id;

        if (this.setting.method == 0) {
            this.method = 1
        } else {

            this.method = this.setting.method
        }
        this.getvisaidentitysFn();
        this.getDoclist();
        setTimeout(() => {
            if (this.visaIdentitys.length) {
                this.checkIdentity(0)
            }
        }, 500)
    },
    props: {
        setting: {
            type: Object,
            default: {},
        }
    },

    methods: {
        refreshDocSettingFn() {
            this.getvisaidentitysFn();
            this.getDoclist();
        },
        getvisaidentitysFn() {
            let vm = this;

            getvisaidentitys({
                id: vm.id,
                token: vm.$store.state.token
            }).then(res => {
                vm.visaIdentitys = res.data
                vm.getdefaultvisaidentitysFn();
            })
        },
        getdefaultvisaidentitysFn() {
            let vm = this;

            getdefaultvisaidentitys({
                token: vm.$store.state.token
            }).then(res => {

                console.log(vm.visaIdentitys, 6666);
                vm.identitysList = res.data;

                vm.identitysList.forEach(item => {
                    vm.visaIdentitys.forEach(elem => {
                        elem.active = false;

                        if (item.id == elem.identityTypeId) {
                            item.active = true;
                        }
                    })
                })
                vm.getQueryvisadocs();
            })
        },
        getQueryvisadocs() {
            let vm = this;

            vm.loading = true;

            let isNeed = ''
            if (!vm.form.isRequire) {
                isNeed = ''
            } else {
                isNeed = vm.form.isRequire == 1 ? 'true' : 'false'
            }


            if (vm.identitysActive == -1) {
                vm.loading = false;
                return;
            }

            queryvisadocs({
                token: vm.$store.state.token,
                VisaId: vm.id,
                IdentityId: vm.visaIdentitys[vm.identitysActive] ? vm.visaIdentitys[vm.identitysActive].identityTypeId : '',
                Method: vm.method,
                'Page.Size': 10,
                'Page.Index': this.pageIndex,
                IsNeed: isNeed,
                Name: vm.form.name,
            }).then(res => {
                console.log(res);
                vm.loading = false;
                this.queryVisaList = res.data.rows;
                this.pageTotal = res.data.total
            })
        },
        reset() {
            let vm = this;
            vm.form = {
                name: '',
                isRequire: '',
            }

            vm.getQueryvisadocs();

        },
        getvisadocsFn() {
            let vm = this;

            this.zlDataLoading = true

            getvisadocs({
                token: vm.$store.state.token,
                identityTypeId: vm.visaIdentitys[vm.identitysActive] ? vm.visaIdentitys[vm.identitysActive].identityTypeId : '',
                Method: vm.method,
                visaId: vm.id,
            }).then(res => {
                console.log(res);
                vm.visaDocs = res.data
                let arr = []

                vm.docList.forEach(item => {
                    item.docs.forEach(elem => {
                        vm.visaDocs.forEach(el => {
                            if (el.docId == elem.id) {
                                // elem.isActive = true;
                                arr.push(elem.id)
                            }
                        })

                    })
                })

                this.checkBoxArr = arr;

                this.tableKey += 1;
                this.zlDataLoading = false

            })
        },

        getDoclist() {
            this.zlDiaLoading = true
            doclist({}).then(res => {
                this.zlDiaLoading = false
                this.docList = res.data;

                this.docList.forEach(item => {
                    item.docs.forEach(elem => {
                        elem.isActive = false;
                    })
                })
            }).catch(() => {
                this.zlDiaLoading = false
            })
        },

        checkIdentity(index) {
            this.identitysActive = index
            this.pageIndex = 1;
            this.getvisaidentitysFn();
        },
        currentChange(val) {
            this.pageIndex = val;
            this.getQueryvisadocs();
        },
        selectMethod() {
            if (this.method == 1) {
                this.method = 2
            } else {
                this.method = 1
            }
            this.getvisaidentitysFn();
        },
        editData() {
            if (this.identitysActive == -1) {
                this.$message({
                    message: '请先选择身份类型！',
                    type: 'warning'
                });
                return
            }
            this.zlDataShow = true;
            this.getvisadocsFn();

        },
        openDialogIdentitys() {
            this.dialogIdentitys = true;
        },
        settingSubmit() {
            let vm = this;
            modifyvisadoc({
                token: vm.$store.state.token,
                method: vm.method,
                visaIdentityId: vm.visaIdentitys[vm.identitysActive].id,
                docIds: vm.checkBoxArr
            }).then(res => {
                console.log(res);
                vm.$message({
                    message: '配置成功！',
                    type: 'success'
                });

                vm.getvisaidentitysFn();
                vm.zlDataShow = false;
            })
        },
        checkBoxZl(e, index, idx) {
            console.log(e);

            this.docList[index].docs[idx].isActive = e;
            this.$forceUpdate();
            this.tableKey = this.tableKey + 1
        },
        identitysCheck() {


            console.log(this.identitysList)
            let arr = [];

            this.identitysList.forEach(item => {
                if (item.active) {
                    arr.push(item.id)
                }
            })


            visaidentitys({
                visaId: this.id,
                ids: arr,

            }).then(res => {
                console.log(res);

                this.$message({
                    message: '修改成功！',
                    type: 'success'
                });

                this.getvisaidentitysFn();
                this.dialogIdentitys = false;
            })
        },
        selectActive(item) {
            item.active = !item.active;
            this.$forceUpdate();
        },
        delProduct(id) {

            this.$confirm(`是否删除该签证资料`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                deletevisadoc({
                    id,
                }).then(res => {

                    this.$message({
                        message: '删除成功！',
                        type: 'success'
                    });
                    this.getQueryvisadocs();

                })
            }).catch(res => {

            })

        },
        switchIsNeedChange(item) {
            item.isNeed = !item.isNeed
            this.$confirm(`是否${!item.isNeed ? '开启' : '关闭'}必须该资料`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifymultipleorisneed({
                    id: item.id,
                    flag: !item.isNeed,
                    type: 1
                }).then(res => {
                    this.getvisaidentitysFn();
                }).catch(res => {

                })
            }).catch(res => {

            })

        },
        switchIsMultipleChange(item) {
            item.isMultiple = !item.isMultiple
            this.$confirm(`是否要${!item.isMultiple ? '开启' : '关闭'}多张图片`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifymultipleorisneed({
                    id: item.id,
                    flag: !item.isMultiple,
                    type: 2
                }).then(res => {
                    this.getvisaidentitysFn();
                })
            }).catch(res => {

            })

        },
        switchIsElectronChange(item) {
            item.isElectron = !item.isElectron
            this.$confirm(`是否要${!item.isElectron ? '开启' : '关闭'}电子`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifymultipleorisneed({
                    id: item.id,
                    flag: !item.isElectron,
                    type: 3
                }).then(res => {
                    this.getvisaidentitysFn();
                })
            }).catch(res => {

            })

        },
        switchIsMailChange(item) {
            item.isMail = !item.isMail
            this.$confirm(`是否要${!item.isMail ? '开启' : '关闭'}邮寄`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifymultipleorisneed({
                    id: item.id,
                    flag: !item.isMail,
                    type: 4
                }).then(res => {
                    this.getvisaidentitysFn();
                })
            }).catch(res => {

            })
        },
        searDocs(item) {
            if (this.input3.trim()) {
                this.searchArr = item.docs.filter(item => item.name.includes(this.input3.trim()));
            } else {
                this.searchArr = []
            }
        },
        openDescFn(row) {
            this.rowInfo = JSON.parse(JSON.stringify(row))
            this.dialogDesc = true
        },
        confirmDescFn() {
            if(this.descLoading) {
                return
            }
            this.descLoading = true
            uploaddocdesc({
                id: this.rowInfo.id,
                desc: this.rowInfo.desc,
            }).then((res) => {
                if (res.code == 1) {
                    this.$message({
                        message: "操作成功！",
                        type: "success",
                    });
                    this.dialogDesc = false;
                    this.getvisaidentitysFn();
                }
                this.descLoading = false
            }).catch(() => {
                this.descLoading = false
            })
        },
        
        async uploadDocFn(file, row) {
            let alyossData = await alyoss(file.file);
            uploaddoc({
                id: row.id,
                path: alyossData,
            }).then((res) => {
                if (res.code === 1) {
                    this.$message({
                        message: "上传成功！",
                        type: "success",
                    });
                    this.getvisaidentitysFn();
                }
            });
        },
        removeDocFn(row) {
            uploaddoc({
                id: row.id,
                path: "",
            }).then((res) => {
                if (res.code === 1) {
                    this.$message({
                        message: "操作成功！",
                        type: "success",
                    });
                    this.getvisaidentitysFn();
                }
            });
        },
        handleNumberChange(val, row) {
            console.log(val, row);
            uploaddocsort({
                id: row.id,
                sort: val
            }).then(res => {
                if(res.code == 1) {
                    this.$message.success('操作成功');
                    this.getQueryvisadocs();
                }
            })
        },
        addCustomDocFn() {
            this.addCustomDocShow = true;
            this.customDocInfo = {};
        },
        editCustomDocFn(item) {
            this.addCustomDocShow = true;
            this.customDocInfo = item;
        },
        importOpenFn() {
          this.importDocShow = true
        }
    }
}
</script>

<style lang="scss">
.zl-class {
  background: none !important;
  border-radius: 12px;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
    background: #f2f8ff;
  }

  .el-dialog__body {
    // width: 808px;
    // height: 680px;
    top: 5vh;
    width: 80vw;
    height: calc(70vh + 150px);
    background: #f1f5ff;
    border-radius: 12px;
    box-sizing: border-box;

    // overflow: hidden;
    // .dialog-zl-table {
    //     height: calc(100% - 100px);
    //     width: 100%;
    // }
    .dialog-zl-wrap {
      height: 100%;
      width: 100%;
    }
    .checkbox-item {
      display: inline-flex;
      align-items: center;
      .el-checkbox__label {
        width: 180px;
        display: inline-flex;
        align-items: center;
        > div {
          max-width: 140px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  &.address-class {
    .el-dialog__body {
    }
  }
}

.identitys-class {
  background: none !important;
  border-radius: 12px;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 464px;
    background: #f1f5ff;
    border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
  }

  &.address-class {
    .el-dialog__body {
      height: 519px;
    }
  }
}


.zl-add-class-dialog {
  background: none !important;
  border-radius: 12px;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 100%;
    background: #f1f5ff;
    border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .dialog-zl-btn {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 24px;

    .zl-btn {
      margin: 0 30px;
      width: 145px;
      height: 38px;
      border-radius: 8px 8px 8px 8px;
    }
  }
}

</style>

<style lang="scss" scoped>
.table-pagination {
  text-align: center;
  margin-top: 16px;
}

.dialog-member-wrap {
  .dialog-member-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;

    .dialog-member-name {
      font-size: 18px;
      font-weight: bold;
    }
    .dialog-member-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dialog-close {
        margin-left: 100px;
      }
    }

    .dialog-close {
      width: 25px;
      height: 25px;
      background: #e0e0e0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-icon-close {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}

.identitys-dialog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;

  .identitys-dialog-item {
    width: 194px;
    height: 54px;
    border-radius: 8px 8px 8px 8px;
    background: #fff;
    margin-bottom: 20px;
    display: flex;
    padding-left: 24px;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;

    &.identitys-dialog-item-active {
      color: #3888e9;
      border: 1px solid #3888e9;
    }

    .identitys-dialog-item-img {
      position: absolute;
      right: 0;
      bottom: 0;
      // transform: scale(.5);
      img {
        display: inline-block;
        width: 50%;
        height: 50%;
        float: right;
      }
    }
  }
}

.box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  .box-input-btn {
    text-align: right;

    .box-input-btn-cz {
      background: #eaf3ff;
      color: #3888e9;
      border: none;
    }

    .box-input-btn-add {
      background: #3888e9;
      color: #fff;
      border: none;
    }
  }
}

.identitys-dialog-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;

  .identitys-btn {
    width: 191px;
    height: 50px;
  }
}

.dialog-zl-btn {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .zl-btn {
    margin: 0 30px;
    width: 145px;
    height: 38px;
    border-radius: 8px 8px 8px 8px;
  }
}

.settins-page {
  height: 100%;
  overflow: hidden;
  display: flex;
  // width: 1005px;
  width: calc(100% - 30px);
  flex: 1;

  .settins-left {
    width: 200px;
    background: #f9faff;
    margin-top: 8px;
    padding: 16px 12px;
    box-sizing: border-box;
    position: relative;

    .identitys-select {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 16px;
      color: #3888e9;
      cursor: pointer;
      line-height: 40px;
    }

    .setting-type {
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        font-size: 24px;
        font-weight: bold;
        color: #3888e9;
      }

      span {
        background: #3888e9;
        color: #fff;
        // height: 18px;
        font-size: 12px;
        padding: 2px 6px;
        border-radius: 2px;
        margin-left: 13px;
      }
    }

    .identitys-list {
      display: flex;
      flex-direction: column;
      margin-top: 23px;

      .identitys-item {
        width: 145px;
        height: 46px;
        border-radius: 8px;
        display: flex;
        padding-left: 22px;
        align-items: center;
        cursor: pointer;

        &.identitys-item-active {
          background: #f2f6ff;
          color: #3888e9;
        }
      }
      .identitys-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }

  .settins-right {
    width: calc(100% - 200px);
    padding: 16px;
    overflow: auto;

    ::v-deep {
      .el-select {
        margin-right: 8px;
      }

      .el-input__inner {
        background: #f2f4f7;
        border: none;
      }

      .el-table__row {
        .cell {
          font-size: 12px;
        }
      }
    }
  }
}
.desc-tooltip-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .tooltip-text {
    min-width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>