<template>
    <div class="page order-detail-child order-detail-input">
      <div class="order-wrap">
  
      <div class="page-card">
        
        <div class="page-card-top">
          <el-upload action="" :http-request="uploadVisapage" v-if="!applicantdocsObj.orderApplicant.visaPage">
            <el-button round class="default">上传签证页 <i class="iconfont icon-shangchuan"></i> </el-button>
          </el-upload>
          <div v-else>
            <span class="fine-name">
              {{ applicantdocsObj.orderApplicant.visaPageName }}
            </span>
            <i class="iconfont icon-search" @click="openFile"></i>
            <i class="el-icon-delete" @click="deleteFile"></i>
          </div>
  
        </div>
        <div class="page-require-box">
          <div class="page-title">必备资料</div>
          <div class="page-item" v-for="(item, index) in requiredList" :key="index">
  
            <!-- 头像 -->
            <div class="page-item-avatar" v-if="item.docId == 10">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" :is-delete="true" @deleteDoc="deleteDoc(item, 0)" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="avater" @input="toImg($event, item)">
                </upload>
  
                <el-image class="page-item-legend" :src="item.legend" alt="" :preview-src-list="[item.legend]"></el-image>
  
                <div class="page-item-desc">
                  <span>资料要求</span>
                  <p style="white-space: pre-line;">{{ item.desc }}</p>
                </div>
              </div>
            </div>
  
            <!-- 护照 -->
            <div class="page-item-avatar" v-else-if="item.docId == 2">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload v-model="item.uploadedData[0].path" @uploading="uploadHandle" :disabled="!isEdit" @deleteDoc="deleteDoc(item, 0)" :is-delete="true" :auto-upload="true" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="passport" @input="toImg($event, item)">
                </upload>
                <div v-if="!item.uploadedData[0].path">
  
                  <div class="page-item-no-passport">
  
                    <el-image class="page-item-legend" :src="item.legend" alt="" :preview-src-list="[item.legend]">
                    </el-image>
  
                    <div class="page-item-desc" style="margin-left: 40px">
                      <span>资料要求</span>
                      <p style="white-space: pre-line;">{{ item.desc }}</p>
                    </div>
                  </div>
                </div>
  
                <!-- 编辑护照信息 -->
                <div class="page-item-input page-input" v-else>
                  <div class="model-upload" v-if="loading">
                    <div class="model-upload-flex">
                      <span style="font-size: 20px;color: #FFF;">识别中</span>
                      <i class="el-icon-loading" style="font-size: 30px;color: #FFF;"></i>
                    </div>
  
                  </div>
                  <div class="page-item-title">护照信息读取</div>
                  <div class="page-item-wrap">
                    <div class="page-item-top">
                      <p class="page-item-waning"><i class="el-icon-warning-outline"></i>证件信息错误可能导致拒签，请仔细核对</p>
                      <p v-if="isEdit" class="page-edit" @click="inputShow = true;"> <i class="iconfont icon-bianji"></i>修改资料 </p>
                    </div>
                    <div class="page-input-form" v-if="inputShow">
                      <div class="dialog-form-wrap">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm1">
                          <el-form-item label="护照号码" prop="customer_PassportNumber">
                            <el-input v-model="ruleForm.customer_PassportNumber" placeholder="请填写护照号码"></el-input>
                          </el-form-item>
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <el-form-item label="姓(中文)" prop="customer_SurName">
                                <el-input v-model="ruleForm.customer_SurName" placeholder="请填写姓(中文)"></el-input>
                              </el-form-item>
  
                              <el-form-item label="名(中文)" prop="customer_Name">
                                <el-input v-model="ruleForm.customer_Name" placeholder="请填写名(中文)"></el-input>
                              </el-form-item>
                              <el-form-item label="性别" prop="customer_Gender">
                                <div class="demo-ruleForm-radio">
                                  <el-radio v-model="ruleForm.customer_Gender" label="M">男</el-radio>
                                  <el-radio v-model="ruleForm.customer_Gender" label="F">女</el-radio>
                                </div>
                              </el-form-item>
                              <el-form-item label="签发日期" prop="customer_PassportIssue">
                                <el-date-picker v-model="ruleForm.customer_PassportIssue" type="date" placeholder="选择签发日期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                              </el-form-item>
                              <el-form-item label="出生地点" prop="customer_ProvinceName">
                                <el-input v-model="ruleForm.customer_ProvinceName" placeholder="请填写出生地点"></el-input>
                              </el-form-item>
                              <el-form-item label="签发地点" prop="customer_PassportProvinceName">
                                <el-input v-model="ruleForm.customer_PassportProvinceName" placeholder="请填写签发地点"></el-input>
                              </el-form-item>
                              <el-form-item label="签发机关" prop="customer_PassportAuthority">
                                <el-input v-model="ruleForm.customer_PassportAuthority" placeholder="请填写签发机关"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="姓(拼音)" prop="customer_SurnameEn">
                                <el-input v-model="ruleForm.customer_SurnameEn" placeholder="请填写姓(拼音)"></el-input>
                              </el-form-item>
                              <el-form-item label="名(拼音)" prop="customer_NameEn">
                                <el-input v-model="ruleForm.customer_NameEn" placeholder="请填写名(拼音)"></el-input>
                              </el-form-item>
                              <el-form-item label="出生日期" prop="customer_BirthDay">
                                <el-date-picker v-model="ruleForm.customer_BirthDay" type="date" placeholder="请选择出生日期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                              </el-form-item>
                              <el-form-item label="有效期至" prop="customer_PassportValidity">
                                <el-date-picker v-model="ruleForm.customer_PassportValidity" type="date" placeholder="选择日期有效期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                              </el-form-item>
                              <el-form-item label="出生地点英文" prop="customer_ProvinceNameEn">
                                <el-input v-model="ruleForm.customer_ProvinceNameEn" placeholder="请填写出生地点英文"></el-input>
                              </el-form-item>
                              <el-form-item label="签发地点英文" prop="customer_PassportProvinceEn">
                                <el-input v-model="ruleForm.customer_PassportProvinceEn" placeholder="请填写签发地点英文"></el-input>
                              </el-form-item>
                              <el-form-item label="签发机关英文" prop="customer_PassportAuthorityEn">
                                <el-input v-model="ruleForm.customer_PassportAuthorityEn" placeholder="请填写签发机关英文"></el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-form-item class="form-item-btn">
  
                            <el-button type="primary" class="default" @click="submitForm('ruleForm')">保存</el-button>
                          </el-form-item>
                        </el-form>
                      </div>
  
                    </div>
  
                    <!-- 护照信息展示 -->
                    <div class="page-input-form" v-else>
                      <div class="dialog-form-wrap">
                        <ul class="demo-list">
                          <li>
                            <span>护照号码</span>
                            <p>{{ ruleForm.customer_PassportNumber }}</p>
                          </li>
                          <li>
                            <span>姓 (中文)</span>
                            <p>{{ ruleForm.customer_SurName }}</p>
                          </li>
  
                          <li>
                            <span>姓（拼音）</span>
                            <p>{{ ruleForm.customer_SurnameEn }}</p>
                          </li>
  
                          <li>
                            <span>名（中文）</span>
                            <p>{{ ruleForm.customer_Name }}</p>
                          </li>
  
                          <li>
                            <span>名（拼音）</span>
                            <p>{{ ruleForm.customer_NameEn }}</p>
                          </li>
  
                          <li>
                            <span>性别</span>
                            <p>{{ ruleForm.customer_Gender == '男' || ruleForm.customer_Gender ==
                                '女' ? ruleForm.customer_Gender : ruleForm.customer_Gender == 'M'
                                ? '男' : '女' }}</p>
                          </li>
  
                          <li>
                            <span>出生日期</span>
                            <p>{{ ruleForm.customer_BirthDay ? $moment(ruleForm.customer_BirthDay).format("YYYY-MM-DD") : '' }}</p>
                          </li>
  
                          <li>
                            <span>签发日期</span>
                            <p>{{ ruleForm.customer_PassportIssue ? $moment(ruleForm.customer_PassportIssue).format("YYYY-MM-DD") : '' }}</p>
  
                          </li>
  
                          <li>
                            <span>有效期至</span>
                            <p>{{ ruleForm.customer_PassportValidity ? $moment(ruleForm.customer_PassportValidity).format("YYYY-MM-DD") : '' }}</p>
                          </li>
  
                          <li>
                            <span>出生地点</span>
                            <p>{{ ruleForm.customer_ProvinceName }}</p>
                          </li>
  
                          <li>
                            <span>出生地点英文</span>
                            <p>{{ ruleForm.customer_ProvinceNameEn }}</p>
                          </li>
  
                          <li>
                            <span>签发地点</span>
                            <p>{{ ruleForm.customer_PassportProvinceName }}</p>
                          </li>
  
                          <li>
                            <span>签发地点英文</span>
                            <p>{{ ruleForm.customer_PassportProvinceEn }}</p>
                          </li>
  
                          <li>
                            <span>签发机关</span>
                            <p>{{ ruleForm.customer_PassportAuthority }}</p>
                          </li>
  
                          <li>
                            <span>签发机关英文</span>
                            <p>{{ ruleForm.customer_PassportAuthorityEn }}</p>
                          </li>
  
                        </ul>
                      </div>
  
                    </div>
                  </div>
  
                </div>
  
              </div>
  
            </div>
  
            <!-- 身份证正反面照 -->
            <div class="page-item-avatar" v-else-if="item.docId == 1 || item.docId == 8">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" :is-delete="true" @deleteDoc="deleteDoc(item, 0)" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="other" @input="toImg($event, item)">
                </upload>
  
                <el-image class="page-item-legend" style="margin-left: 36px" :src="item.legend" alt="" :preview-src-list="[item.legend]"> </el-image>
  
                <div class="page-item-desc" v-if="item.desc" style="margin-left: 36px">
                  <span>资料要求</span>
                  <p style="white-space: pre-line;">{{ item.desc }}</p>
                </div>
              </div>
            </div>
  
            <!-- 其他必填资料 -->
            <div v-else>
              <!-- 其他资料 - 多文件上传 -->
              <div v-if="item.isMultiple">
                <span class="page-item-name" style="margin-bottom: 12px">{{ item.name }}</span>
                <div class="page-item-content">
                  <div v-for="(elem, idx) in item.uploadedData" :key="idx" class="page-item-content-list">
                    <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" class="page-item-content-item" :image-url="elem.path" :drag="'drag'" @deleteDoc="deleteDoc(item, idx)" :is-delete="true" thisType="other" @input="toImg($event, item, idx)">
                    </upload>
                  </div>
                </div>
  
                <div class="page-item-desc-2">
                  <span>资料要求</span>
                  <p style="white-space: pre-line;">{{ item.desc }}</p>
                </div>
              </div>
              <!-- 其他资料 - 单文件上传 -->
              <div class="page-item-avatar" v-else>
                <span class="page-item-name">{{ item.name }}</span>
                <div class="page-item-flex">
                  <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" :is-delete="true" @deleteDoc="deleteDoc(item, 0)" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="other" @input="toImg($event, item)">
                  </upload>
  
                  <el-image class="page-item-legend" style="margin-left: 36px" v-if="item.legend" :src="item.legend" alt="" :preview-src-list="[item.legend]"></el-image>
  
                  <div class="page-item-desc" style="margin-left: 36px">
                    <span>资料要求</span>
                    <p style="white-space: pre-line;">{{ item.desc }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-title">其他资料</div>
          <div class="page-item" v-for="(item, index) in addNewArr" :key="'addNewArr' + index">
            <!-- 其他资料 - 单文件上传 -->
            <div class="page-item-avatar" v-if="!item.isMultiple">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" :is-delete="true" @deleteDoc="deleteDoc(item, 0)" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="other" @input="toImg($event, item)">
                </upload>
  
                <el-image class="page-item-legend" style="margin-left: 36px" v-if="item.legend" :src="item.legend" alt="" :preview-src-list="[item.legend]"></el-image>
  
                <div class="page-item-desc" style="margin-left: 36px">
                  <span>资料要求</span>
                  <p style="white-space: pre-line;">{{ item.desc }}</p>
                </div>
              </div>
            </div>
            <!-- 其他资料 - 多文件上传 -->
            <div v-if="item.isMultiple">
              <span class="page-item-name" style="margin-bottom: 12px">{{ item.name }}</span>
              <div class="page-item-content">
                <div v-for="(elem, idx) in item.uploadedData" :key="idx" class="page-item-content-list">
                  <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" class="page-item-content-item" :image-url="elem.path" :drag="'drag'" @deleteDoc="deleteDoc(item, idx)" :is-delete="true" thisType="other" @input="toImg($event, item, idx)">
                  </upload>
                </div>
              </div>
  
              <div class="page-item-desc-2">
                <span>资料要求</span>
                <p style="white-space: pre-line;">{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import {
      applicantdocs, postuploadpassport, savepassportinfo, savedocimg, savedocimgnodel, deletedocimgtwo, queryorderapplicants, orderdetail, addorderapplicants, uploadvisapage
  } from '@/api/order.js'
  import upload from '@/components/upload.vue';
  import alyoss from "@/utils/alyoss";
  
  
  export default {
    data() {
      return {
        isEdit: true,
        uploading: true,
        inputShow: false,
        topShow: false,
      //   id: '',
      //   detailId: '',
        loading: false,
        applicantdocsObj: {
          orderApplicant: {
            identityTypeName: '',
            applicantName: ''
          }
        },
        orderDetail: {
          order: {},
          orderApplicants: [],
          visaDetail: {
            basicInfo: [],
            offlineVisaDocs: [],
            visa: {},
            visaDocs: [],
          },
        },
        bbList: [],
        avatar: '',
        requiredList: [],   //  常见资料
        other: [],  //  其他资料
        addNewArr: [],
        statusSelect: [],
        userList: [],
        applicationForm: {},
        ruleForm: {
          customer_PassportNumber: "",
          customer_SurName: '',
          customer_SurnameEn: '',
          customer_Name: '',
          customer_NameEn: '',
          customer_Gender: 'M',
          customer_GenderName: '男',
          customer_PassportAuthority: '',
          customer_PassportAuthorityEn: '',
          customer_BirthDay: '',  //  出生日期
          customer_PassportIssue: '', //  签发日期
          customer_PassportValidity: '',  //  有效日期
          customer_SavePassportInfo: false,
        },
  
        downList: {
          pageIndex: 1,
          pageSize: 10,
          total: '',
          data: []
        },
  
        pageIndex: 1,
        pageSize: 10,
        total: 0,
  
        rules: {
          customer_PassportNumber: [
            { required: true, message: '请填写护照号码', trigger: ['click', 'change', 'blur'] }
          ],
          customer_SurName: [
            { required: true, message: '请填写姓（中文）', trigger: ['click', 'change', 'blur'] },
          ],
          customer_SurnameEn: [
            { required: true, message: '请填写姓（拼音）', trigger: ['click', 'change', 'blur'] }
          ],
          customer_Name: [
            { required: true, message: '请填写名（中文）', trigger: ['click', 'change', 'blur'] },
          ],
  
          customer_NameEn: [
            { required: true, message: '请填写名（拼音）', trigger: ['click', 'change', 'blur'] },
          ],
  
          customer_Gender: [
            { required: true, message: '请选择性别', trigger: ['click', 'change', 'blur'] },
          ],
          customer_BirthDay: [
            { required: true, message: '请选择出生日期', trigger: ['click', 'change', 'blur'] },
          ],
          customer_PassportIssue: [
            { required: true, message: '选择签发日期', trigger: ['click', 'change', 'blur'] },
          ],
          customer_PassportValidity: [
            { required: true, message: '选择日期有效期', trigger: ['click', 'change', 'blur'] },
          ],
          customer_PassportProvinceName: [
            { required: true, message: '请填写签发地点', trigger: ['click', 'change', 'blur'] },
          ],
          customer_PassportProvinceEn: [
            { required: true, message: '请填写签发地点英文', trigger: ['click', 'change', 'blur'] },
          ],
          customer_ProvinceName: [
            { required: true, message: '请填写出生地点', trigger: ['click', 'change', 'blur'] },
          ],
          customer_ProvinceNameEn: [
            { required: true, message: '请填写出生英文', trigger: ['click', 'change', 'blur'] },
          ],
          customer_PassportAuthority: [
            { required: true, message: '请填写签发机关', trigger: ['click', 'change', 'blur'] },
          ],
  
          customer_PassportAuthorityEn: [
            { required: true, message: '请填写签发机关英文', trigger: ['click', 'change', 'blur'] },
          ],
        },
        showMore: false,
        newAddUserShow: false,
        batchShow: false,
        isQrcodeImg: false,
        qrcodeImgUrl: ''
      }
    },
    props: {
      // 申请人id
      detailId: {
          type: [String, Number],
          default: ''
      }
    },
    components: {
      upload,  
    }, 
    watch: {
      detailId: {
          deep: true,
          immediate: true,
          handler() {
              this.getApplicantdocs();
          }
      }
    },
    mounted() {
      console.log(this.$router.history)
      this.id = this.$router.history.current.params.id.split('_')[0] 
   
      // 滚动条的获取
      window.addEventListener('scroll', this.handleScrollx, true) 
      // if (this.detailId > 0) {
  
      // this.getApplicantdocs();
      // } else {
      // this.detailId = vm.userList[0].id
      // this.getApplicantdocs();
      // }
    },
    methods: {
      uploadHandle(val) {
        var vm = this;
        vm.uploading = val;
      },
      getApplicantdocs() {
        // 查询申请人资料
        applicantdocs({
          ApplicantId: this.detailId,
        }).then(res => {
          this.applicantdocsObj = res.data
  
          let required = []
          let other = []
          if (this.applicantdocsObj.orderApplicant.visaPage) {
            let arr = this.applicantdocsObj.orderApplicant.visaPage.split('/'),
              fineName = arr[arr.length - 1];
            this.applicantdocsObj.orderApplicant.visaPageName = fineName
          }
          this.applicantdocsObj.orderApplicant.electronicData.forEach(item => {
            // if (item.docId == 1 || item.docId == 2 || item.docId == 8 || item.docId == 10) {
            if (item.isNeed) {
              // if (item.docId != 2) {
              if (item.uploadedData.length == 0) {
  
                item.uploadedData = [{
                  id: '',
                  docId: '',
                  path: '',
                }]
              }
  
              // }
              required.push(item)
            } else {
              other.push(item);
            }
          })
  
          this.ruleForm = this.applicantdocsObj.passPortInfo
          this.inputShow = !this.ruleForm.customer_SavePassportInfo
          let arr = []
          let newArr = []
          let addNewArr = []
          other.forEach(item => {
            arr.push(item.docId)
          })
  
          newArr = Array.from(new Set(arr));
  
  
          newArr.forEach(item => {
            addNewArr.push({
              docId: item,
              pathList: [],
              pathLId: [],
              uploadedData: [],
              path: '',
              isMultiple: false,
              desc: '',
              name: '',
              id: ''
            })
          })
  
          other.forEach(item => {
            addNewArr.forEach(elem => {
              if (item.docId == elem.docId) {
                elem.path = item.path
                elem.isMultiple = item.isMultiple
                elem.desc = item.desc
                elem.name = item.name
                elem.id = item.id
                if (item.uploadedData.length == 0) {
                  elem.uploadedData = [{
                    id: '',
                    docId: '',
                    path: '',
                  }]
                } else {
                  elem.uploadedData = item.uploadedData
  
                  if (item.isMultiple) {
                    elem.uploadedData.push({
                      id: '',
                      docId: '',
                      path: '',
                    })
                  }
                }
  
                if (item.isMultiple) {
                  if (item.path) {
  
                    elem.pathList.push(item.path)
                    elem.pathLId.push(item.id)
                  }
                }
              }
            })
          })
          addNewArr.forEach(item => {
            if (item.isMultiple) {
              item.pathList.push('')
            }
          })
  
          this.addNewArr = addNewArr;
          this.requiredList = required;
          this.other = other;
          // this.submitForm('passportForm');
        })
      }, 
      handleScrollx() {
        if (window.pageYOffset > 200) {
          this.topShow = true;
        } else {
          this.topShow = false;
        }
      },
      toImg(e, item, idx) {
  
        let vm = this;
        if (item.docId == 2) {
          this.loading = true;
          postuploadpassport({
            orderApplicantId: vm.detailId,
            docId: item.docId,
            url: e,
            token: vm.$store.state.token
          }).then(res => {
            if (res.code == 1) {
              this.ruleForm = res.data;
              this.inputShow = true;
  
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              });
            }
            this.loading = false;
          })
  
        } else {
          if (item.isMultiple) {
            savedocimgnodel({
              orderApplicantId: vm.detailId,
              docId: item.docId,
              url: e,
              docImgId: item.uploadedData[idx].id ? item.uploadedData[idx].id : 0,
              token: vm.$store.state.token
            }).then(res => {
              vm.getApplicantdocs();
            })
          } else { 
            savedocimg({
              orderApplicantId: vm.detailId,
              docId: item.docId,
              url: e,
              docImgId: item.uploadedData[0].id ? item.uploadedData[0].id : 0,
              token: vm.$store.state.token
            }).then(res => {
              this.getApplicantdocs();
            })
          }
        }
      },
  
      deleteDoc(item, idx) {
        let vm = this;
        deletedocimgtwo({
          docImgId: item.uploadedData[idx].id,
          token: vm.$store.state.token
        }).then(res => {
          this.getApplicantdocs();
        })
      }, 
   
      selectUser(item) {
        // console.log('itemitem', item);
        let vm = this;
        vm.detailId = item.id;
        vm.applicationForm = item;
        vm.isEdit = [8, 10, 14, 16, 20].indexOf(vm.applicationForm.status) === -1;
        vm.getApplicantdocs();
      },
      isChong(array) {
        array.sort();  //数组排序
        var reNum = 0;  //返回结果
        //遍历整个数组对象
        for (var i = 0; i < array.length; i++) {
          //跳过最后一个元素的比较
          if (i + 1 == array.length) {
            continue;
          }
          //判断相邻的元素是否相同
          if (array[i] == array[i + 1]) {
            reNum += 1;
          }
        }
        return reNum;
      },
      submitForm(formName) {
        let vm = this;
        // return;
        vm.$refs[formName][0].validate((valid) => {
          if (valid) {
            this.inputShow = true;
            savepassportinfo({
              ...vm.ruleForm,
              customer_SavePassportInfo: true,
              token: vm.$store.state.token
            }).then(res => {
              // this.$message({
              //     message: '保存成功',
              //     type: 'success'
              // });
              this.inputShow = false;
            })
  
          } else {
            // this.inputShow = false;
            return false;
          }
        });
      },
      copySuccess() {
        this.$message({
          message: '复制成功！',
          type: 'success'
        });
      },
      copyError() {
        this.$message({
          message: '复制失败，请手动复制！',
          type: 'error'
        });
      },
      async uploadVisapage(file) {
        let alyossData = await alyoss(file.file);
        uploadvisapage({
          orderApplicantId: this.applicantdocsObj.orderApplicant.id,
          visaPage: alyossData,
        }).then(res => {
          if (res.code === 1) {
            this.$message({
              message: '上传成功！',
              type: 'success'
            });
          //   this.getApplicantdocs()
          //   this.queryorderapplicantsFn()
          }
        })
      },
      openFile() {
        window.open(this.applicantdocsObj.orderApplicant.visaPage)
      },
      
      deleteFile() {
        this.$confirm('是否要删除该签证页?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          uploadvisapage({
            orderApplicantId: this.applicantdocsObj.orderApplicant.id,
            visaPage: '',
          }).then(res => {
            if (res.code === 1) {
              this.$message({
                message: '删除成功！',
                type: 'success'
              });
              this.getApplicantdocs()
              this.queryorderapplicantsFn()
            } else {
              this.$message.error(res.msg)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }, 
    }
  }
  </script>
  
  
  <style lang="scss" scoped>
  .page {
      padding: 0 !important;
  }
  .page-btn {
    height: 100px;
    text-align: right;
    margin-top: 34px;
    margin-right: 24px;
    box-sizing: border-box;
  
    .default {
      width: 132px;
      height: 49px;
      font-size: 24px;
    }
  }
  
  .page-order-table-box {
    // position: absolute;
    // left: 0px;
    // top: 0;
    position: fixed;
    height: 671px;
    width: 202px;
    background: #fff;
    border-radius: 8px;
  
    .page-order-table-box-title {
      padding: 16px 15px 13px 24px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f3f3f3;
  
      span {
        font-size: 20px;
      }
  
      .page-order-table-box-icon {
        width: 25px;
        height: 25px;
        background: #ffffff;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
  
        i {
          font-size: 12px;
        }
      }
    }
  }
  
  .order-detail-child {
    min-height: 800px;
    background: #f7f8fa;
    padding-bottom: 100px;
  
    .page-card-top-fixd {
      position: fixed;
      top: 0;
      left: 50%;
      width: 1240px;
      height: 76px;
  
      z-index: 11;
      background: #fff;
      transform: translateX(-50%);
      // padding-top: 10px;
      box-sizing: border-box;
    }
  }
  
  .page-card-top {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1000px;
    // left: 210px;
    // position: absolute;
    background-color: #fff;
  
    .page-card-top-flex {
      display: flex;
      align-items: center;
    }
  
    .page-top-back {
      font-size: 16px;
      color: #3888e9;
      cursor: pointer;
  
      i {
        color: #3888e9;
        font-weight: bold;
      }
    }
  
    .page-top-name {
      margin-left: 32px;
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
    }
  
    .page-top-name-select {
      width: 112px;
      border-radius: 8px;
      background: #eceef1;
    }
  
    .page-top-identity {
      margin-left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #666666;
    }
  
    .fine-name {
      color: #3888e9;
      margin-right: 5px;
    }
  
    i {
      cursor: pointer;
      display: inline-block;
    }
  
    .icon-search {
      color: #3888e9;
      margin-right: 10px;
    }
  
    .el-icon-delete {
      font-size: 18px;
      color: #ff5967;
    }
  }
  
  .order-wrap {
    width: 780px;
    margin: 0 auto;
    position: relative;
  
    .page-path {
      margin: 16px 0;
      font-size: 12px;
      // color: #CCCCCC;
      margin-left: -220px;
      color: #a19f9f;
  
      span {
        cursor: pointer;
      }
    }
  
    .page-card {
      width: 1000px;
      position: relative;
  
      .page-require-box {
        width: 1000px;
        // position: absolute;
        // left: 210px;
        // top: 85px;
        padding-bottom: 80px;
        // height: 585px;
        // overflow: auto;
  
        .page-title {
          height: 42px;
          padding-left: 24px;
          font-size: 16px;
          color: #3888e9;
          display: flex;
          align-items: center;
          background: #f2f8ff;
        }
  
        .page-item {
          padding: 20px 24px;
          background: #fff;
          border-bottom: 5px solid #f7f8fa;
  
          .page-item-desc-2 {
            margin-top: 20px;
  
            span {
              font-size: 16px;
            }
  
            p {
              font-size: 14px;
              color: #999999;
              margin-top: 8px;
            }
          }
  
          .page-item-content {
            margin-top: 15px;
            width: 1200px;
            display: flex;
            flex-wrap: wrap;
  
            .page-item-content-list {
              margin-right: 38px;
              margin-bottom: 20px;
            }
          }
  
          .page-item-avatar {
            .page-item-name {
              display: block;
              margin-bottom: 12px;
            }
  
            .page-item-flex {
              display: flex;
              //  align-items: center;
  
              .page-item-no-passport {
                display: flex;
                align-items: center;
                margin-left: 77px;
                // margin-top: 38px;
              }
  
              .page-input {
                width: 734px;
                margin-left: 26px;
                position: relative;
  
                .model-upload {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: #000000;
                  opacity: 0.45;
                  z-index: 2;
                  display: flex;
                  justify-content: center;
                  align-items: center;
  
                  .model-upload-flex {
                    display: flex;
                    align-items: center;
                  }
                }
  
                .page-item-title {
                  width: 100%;
                  height: 38px;
                  background: #f2f8ff;
                  color: #3888e9;
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
  
                .page-item-waning {
                  color: #ff5967;
  
                  .el-icon-warning-outline {
                    margin-right: 8px;
                  }
                }
  
                .page-item-wrap {
                  background: #f7f8fa;
                  min-height: 400px;
                  width: 100%;
                  padding: 12px 28px;
                  box-sizing: border-box;
  
                  .page-item-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
  
                    .page-edit {
                      color: #5f9fed;
                      cursor: pointer;
                    }
                  }
  
                  .demo-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 16px;
                    li:first-child {
                      width: 330px;
                    }
                    li {
                      width: 50%;
                      display: flex;
                      margin-bottom: 24px;
  
                      span {
                        width: 90px;
                        flex-wrap: wrap;
                        color: #666;
                        font-size: 14px;
                        margin-top: 5px;
                      }
  
                      p {
                        width: calc(100% - 95px);
                        font-size: 16px;
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
  
              .page-item-legend {
                width: 222px;
                height: 151px;
                margin-left: 16px;
              }
  
              .page-item-desc {
                max-width: 336px;
                flex: flex;
                flex-direction: column;
                margin-left: 20px;
  
                span {
                  font-size: 16px;
                }
  
                p {
                  font-size: 14px;
                  color: #999999;
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  
    .left-wrap {
      position: absolute;
      left: -220px;
      top: 32px;
      width: 202px;
  
      .left-top {
        background: #ffffff;
        border-radius: 8px;
        color: #333;
        padding: 8px;
  
        p {
          font-size: 14px;
          text-align: left;
          margin-bottom: 10px;
  
          .nation-img {
            display: inline-block;
            width: 20px;
            height: 14px;
            margin-right: 5px;
          }
        }
  
        .left-content-item {
          margin-bottom: 5px;
  
          .title {
            display: inline-block;
            width: 60px;
            text-align-last: justify;
            position: relative;
  
            &::after {
              content: ":";
              position: absolute;
              right: -4px;
              top: -1px;
            }
          }
  
          .content {
            margin-left: 10px;
          }
  
          .icon-fuzhi {
            color: #3888e9;
            margin-left: 10px;
            cursor: pointer;
          }
  
          .source-type {
            display: inline-block;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            background: #4791ea;
            border-radius: 4px;
            color: #fff;
            font-size: 10px;
            margin-right: 5px;
          }
        }
        .page-order-status-name {
          background: #ddf0dd;
          color: #1c9c1c;
          border-radius: 4px;
          font-size: 10px;
          padding: 2px 4px;
          margin-left: 0;
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 0;
  
          &.sty-red {
            background: #fbdbdb;
            color: #e41313;
          }
  
          &.sty-yello {
            background: #fdf5dc;
            color: #eebb17;
          }
        }
  
        .more-switch {
          cursor: pointer;
          border-top: 1px solid #ebebeb;
          margin-top: 10px;
  
          p {
            color: #0094e8;
            text-align: center;
            margin: 5px 0 0 0;
          }
        }
      }
  
      .left-table {
        background: #ffffff;
        border-radius: 8px;
        color: #333;
        padding: 8px;
        margin-top: 15px;
  
        .left-table-btn {
          border-bottom: 1px solid #ebebeb;
          height: 35px;
          text-align: center;
          padding: 5px 10px;
          display: flex;
  
          .btn {
            width: 80px;
            font-size: 11px;
            height: 30px;
            text-align: center;
            padding: 0;
          }
        }
  
        .page-order-ul {
          padding: 10px;
          padding-bottom: 0px;
          min-height: 500px;
  
          .page-order-li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 35px;
            padding: 0 10px;
            margin-bottom: 10px;
  
            &.page-order-active {
              background: #3888e9;
              box-shadow: 0px 3px 10px 1px rgba(56, 136, 233, 0.57);
              border-radius: 8px 8px 8px 8px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  
  .footer-wrap {
    display: none !important;
  }
  
  .demo-ruleForm1 {
    ::v-deep .el-date-editor.el-input {
      width: 117px;
      .el-input--suffix .el-input__inner {
        padding-right: 0;
      }
      .el-input__icon {
        width: 10px;
      }
    }
  }
  </style>